<template>
  <div>
    <v-select
      outlined
      v-model="period"
      :items="periods"
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Pour la période"
    ></v-select>
  </div>
</template>

<script>
import isEqual from "lodash/isEqual";
import { computed, onMounted, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";
import { getDateStr, ONE_DAY_MICROTIME } from "@/utils/dates";

const COMPONENT_NAME = "PeriodFilter";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - 1 * ONE_DAY_MICROTIME);

export default {
  name: COMPONENT_NAME,
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    // =>  METHODS
    const getDatesForPeriod = (p) => {
      const startDate = new Date(YESTERDAY.getTime() - p * ONE_DAY_MICROTIME);

      return [getDateStr(startDate), getDateStr(YESTERDAY)];
    };

    // =>  COMPUTED
    const period = computed({
      get: () => store.getters["marmiton/getMarmitonPeriod"],
      set(e) {
        store.dispatch("marmiton/updateMarmitonPeriod", e);
        if (e > 0) {
          store.dispatch("marmiton/updateMarmitonDates", getDatesForPeriod(e));
        }
      },
    });

    const dates = computed(() => store.getters["marmiton/getMarmitonDates"]);

    // =>  WATCH
    watch(period, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        // is_multiple: false,
        is_integer: true,
        // is_boolean: false,
      });
    });

    watch(dates, (newValue) => {
      if (period.value === 0) {
        return;
      }

      const checkDates = getDatesForPeriod(period.value);
      if (!isEqual(newValue, checkDates)) {
        store.dispatch("marmiton/updateMarmitonPeriod", 0);
      }
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: "marmiton/updateMarmitonPeriod",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: period.value,
        // is_multiple: false,
        is_integer: true,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      period,
      periods: [
        {
          id: 0,
          name: "",
        },
        {
          id: 30,
          name: "30 derniers jours",
        },
        {
          id: 91,
          name: "3 derniers mois",
        },
        {
          id: 182,
          name: "6 derniers mois",
        },
        {
          id: 365,
          name: "12 derniers mois",
        },
      ],
    };
  },
};
</script>

<style></style>
