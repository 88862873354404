<template>
  <v-card id="performancesTable">
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <search-type store="regie" anchor="performancesTable" />
            </v-col>
            <v-col cols="5">
              <search-column
                :columns="['category', 'redac', 'title', 'url']"
                store="regie"
                anchor="performancesTable"
              />
            </v-col>
            <v-col cols="2">
              <apply-search :disabled="searchDisabled" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <table-stats :data="stats.stats"></table-stats>
        </v-col>
      </v-row>

      <dot-menu
        :isLoading="loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData: excelButton.data,
          filename: `perf-par-article-${startDate}_${endDate}.xlsx`,
          disabled: exportDisabled,
        }"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :page="pagination.options.page"
        :pageCount="pagination.numberOfPages"
        :headers="headers"
        :items="pagination.items"
        :options.sync="pagination.options"
        :server-items-length="pagination.total"
        :loading="loading"
        :sort-by="pagination.options.sortBy"
        :sort-desc="true"
        class="elevation-1"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="pagination.options.itemsPerPage"
      >
        <template v-slot:item.title="{ item }">
          <div class="truncateCell" :title="item.title">
            {{ item.title }}
          </div>
        </template>
        <template v-slot:item.adn_post_url_path_identifier="{ item }">
          <div class="truncateCell" :title="item.adn_post_url_path_identifier">
            <a :href="item.url" target="_blank">{{
              item.adn_post_url_path_identifier
            }}</a>
          </div>
        </template>
        <template v-slot:item.ingredients="{ item }">
          <div class="truncateCell" :title="item.ingredients">
            {{ item.ingredients }}
          </div>
        </template>
        <template v-slot:item.meta_description="{ item }">
          <div class="truncateCell" :title="item.meta_description">
            {{ item.meta_description }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <progress-bar :download="download" />
  </v-card>
</template>

<style scoped>
.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>

<script>
import debounce from "lodash/debounce";
import {
  computed,
  del,
  onMounted,
  reactive,
  ref,
  set,
  watch,
} from "@vue/composition-api";

import ApplySearch from "@/components/common/filters/ApplySearch";
import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import SearchColumn from "@/components/common/filters/SearchColumn";
import SearchType from "@/components/common/filters/SearchType";
import TableStats from "@/components/regie/marmiton/cards/TableStatsCard";
import { extrapolateDatasetWithConsentRatio } from "@/components/regie/marmiton/utils/unapplyConsentRatio";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  decodeHtmlEntities,
  formatCurrency,
  formatNumber,
} from "@/utils/formatting";

export default {
  name: "PerfPerArticle",
  components: {
    ApplySearch,
    DotMenu,
    ProgressBar,
    SearchColumn,
    SearchType,
    TableStats,
  },
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    ingredientsToInclude: {
      type: String,
      default: "",
    },
    ingredientsToExclude: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { can, store } = useProxy();
    const { axiosGet } = useAxios();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const canSeeAllData = can("manage", "regie_with_ca");

    const isOptionsInitialized = ref(false);
    const forceCall = ref(false);
    const loading = ref(false);

    const pagination = reactive({
      numberOfPages: 0,
      total: 0,
      items: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [canSeeAllData ? "ca" : "views"],
        sortDesc: [true],
      },
    });
    const search = ref("");
    const download = reactive(download_initial_state());

    const stats = reactive({
      stats: {},
    });

    const excelButton = reactive({
      data: new Blob([""], { type: "text/plain" }),
    });

    // =>  METHODS
    const performances_url_params = (forExport = false) => {
      return {
        start_date: props.startDate,
        end_date: props.endDate,
        ingredients_to_include: props.ingredientsToInclude,
        ingredients_to_exclude: props.ingredientsToExclude,
        search_text: search.value,
        search_column: searchColumn.value,
        search_type: searchType.value,
        ...(dish.value ? { dish: dish.value } : {}),
        ...(cost.value ? { cost: cost.value } : {}),
        ...(duration.value ? { duration: duration.value } : {}),
        ...(rating.value ? { rating: rating.value } : {}),
        ...(rates.value ? { rates: rates.value } : {}),
        ...(forExport
          ? {}
          : {
              page: Math.max(pagination.options.page - 1, 0),
              limit: pagination.options.itemsPerPage,
            }),
        sort_by: pagination.options.sortBy[0],
        sort_desc: pagination.options.sortDesc[0],
      };
    };

    const formatResult = (data) => {
      return data.items.map((item) => {
        item = extrapolateDatasetWithConsentRatio(item);

        return {
          ...item,
          ingredients: (item.ingredients ?? "").split(",").sort().join(", "),
          created: (item.created ?? "").split("T")[0],
          ...(canSeeAllData && item?.ca !== undefined
            ? { ca: formatCurrency(item.ca) }
            : {}),
          views: formatNumber(item.views),
          ...(canSeeAllData
            ? {
                sessions: formatNumber(item.sessions),
                views_per_session: formatNumber(item.views / item.sessions),
              }
            : {}),
          chars_count: formatNumber(item.chars_count),
          title: decodeHtmlEntities(item.title),
          meta_description: decodeHtmlEntities(item.meta_description),
        };
      });
    };

    const checkDates = () => {
      if (
        !props.startDate ||
        props.startDate.length !== 10 ||
        !props.endDate ||
        props.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");

        return false;
      }

      return true;
    };

    const exportExcel = async () => {
      if (!checkDates()) {
        return;
      }

      loading.value = true;
      download.running = true;
      const { data } = await axiosGet(
        "/regie/marmiton/performances/export_xlsx",
        performances_url_params(true),
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      excelButton.data = data;
      loading.value = false;
    };

    const debouncedSearch = debounce(async () => {
      if (pagination.options.page !== 1) {
        pagination.options.page = 1;
        // updateData automatically call through watcher
      } else {
        getData();
      }
    }, 500);

    const debouncedGetData = debounce(async () => {
      await getData();
    }, 500);

    const getData = async () => {
      if (
        (applyFilters.value || applySearch.value || forceCall.value) &&
        !loading.value
      ) {
        if (!checkDates()) {
          return;
        }
        loading.value = true;
        store.dispatch("marmiton/updateMarmitonStatsForPeriod", {});
        const { data: performancesData } = await axiosGet(
          "/regie/marmiton/performances",
          performances_url_params()
        );

        pagination.items = formatResult(performancesData);
        pagination.total = performancesData.total;
        pagination.numberOfPages =
          performancesData.total / pagination.options.itemsPerPage;

        performancesData.stats = extrapolateDatasetWithConsentRatio(
          performancesData.stats
        );

        set(stats, "stats", performancesData);

        // Doesn't depends on pagination.options (only run on first page):
        if (Math.max(pagination.options.page - 1, 0) === 0) {
          const { data: statsData } = await axiosGet(
            "/regie/marmiton/sessions-for-period",
            {
              start_date: props.startDate,
              end_date: props.endDate,
            }
          );

          const extrapolatedStatsData =
            extrapolateDatasetWithConsentRatio(statsData);

          await store.dispatch(
            "marmiton/updateMarmitonStatsForPeriod",
            extrapolatedStatsData
          );
        }

        loading.value = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        store.dispatch("common/updateApplyFilters", false);
        store.dispatch("common/updateApplySearch", false);
      }

      forceCall.value = false;
    };

    // =>  COMPUTED
    const applyFilters = computed(
      () => store.getters["common/getApplyFilters"]
    );
    const applySearch = computed(() => store.getters["common/getApplySearch"]);
    const searchType = computed(() => store.getters["regie/getSearchType"]);
    const searchColumn = computed(() => store.getters["regie/getSearchColumn"]);
    const exportDisabled = computed(
      () => loading.value || pagination.items.length === 0
    );
    const dish = computed(() => store.getters["marmiton/getDish"]);
    const cost = computed(() => store.getters["marmiton/getCost"]);
    const duration = computed(() => store.getters["marmiton/getDuration"]);
    const rating = computed(() => store.getters["marmiton/getRating"]);
    const rates = computed(() => store.getters["marmiton/getRates"]);

    // =>  WATCH
    watch(
      () => pagination.options,
      () => {
        if (isOptionsInitialized.value) {
          // Not on initialization : wait for a real user change
          forceCall.value = true;
        }

        isOptionsInitialized.value = true;
        debouncedGetData();
      }
    );

    watch(
      () => search.value,
      (newValue) => {
        store.dispatch("marmiton/updateMarmitonSearchText", newValue);

        addQueryStringParam(
          {
            param: URL_PARAM_NAMES["Search"],
            value: newValue,
            // is_multiple: false,
            // is_integer: false,
            // is_boolean: false,
          },
          "performancesTable"
        );
      }
    );

    watch(applyFilters, () => {
      debouncedGetData();
    });

    watch(applySearch, () => {
      debouncedSearch();
    });

    watch(
      () => download.percentCompleted,
      (newValue) => {
        if (newValue >= 100) {
          setTimeout(() => {
            const initialState = download_initial_state();
            for (const [key, value] of Object.entries(initialState)) {
              set(download, key, value);
            }
            for (const key of Object.keys(download)) {
              if (!(key in initialState)) {
                del(download, key);
              }
            }
          }, 500);
        }
      }
    );

    // =>  HOOKS
    onMounted(async () => {
      const initialUrlValue = await synchronizeFilterWithQueryString({
        dispatcher: "marmiton/updateMarmitonSearchText",
        param: URL_PARAM_NAMES["Search"],
        value: search.value,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });

      if (initialUrlValue) {
        search.value = initialUrlValue;
      }
    });

    // =>  DATA
    return {
      loading,
      stats,
      search,
      pagination,
      exportExcel,
      excelButton,
      download,
      exportDisabled,
      searchDisabled: loading,
      headers: [
        {
          text: "Date création",
          align: "start",
          value: "created",
        },
        {
          text: "Rédac",
          align: "start",
          value: "redac_name",
        },
        {
          text: "Titre",
          value: "title",
          sortable: false,
        },
        {
          text: "URL",
          value: "url_path_identifier",
        },
        {
          text: "Ingrédients",
          value: "ingredients",
          sortable: false,
        },
        {
          text: "Méta descr",
          value: "meta_description",
          sortable: false,
        },
        ...(canSeeAllData
          ? [
              {
                text: "CA",
                value: "ca",
              },
            ]
          : []),
        {
          text: "Vues",
          value: "views",
        },
        ...(canSeeAllData
          ? [
              {
                text: "Sessions",
                value: "sessions",
              },
              {
                text: "Vues / Session",
                value: "views_per_session",
              },
            ]
          : []),
        {
          text: "Nb chars",
          value: "chars_count",
        },
        {
          text: "Catégorie",
          value: "category",
        },
        {
          text: "New",
          value: "is_new",
          sortable: false,
        },
        {
          text: "Diapo",
          value: "is_diapo",
          sortable: false,
        },
        {
          text: "Typologie",
          value: "ga_typology",
          sortable: false,
        },
        {
          text: "Type",
          value: "content_type",
          sortable: false,
        },
      ],
    };
  },
};
</script>
